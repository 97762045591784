import * as React from "react";
import { createHttpClient } from "../utils/HttpClient";
import { SAML_RELAYSTATE_KEY, SAML_REQUEST_KEY } from "../auth/AuthProvider";
import ErrorMessage from "../components/Common/ErrorMessage/ErrorMessage";
import Footer from "../components/HomePage/Footer/Footer";
import HeaderNavUnAuthenticated from "../components/HomePage/HeaderNav/HeaderNavUnAuthenticated";
import Modal from "../components/Common/modal/Modal";
import { useTranslation } from "react-i18next";
import "./VerifyEmail.scss";

interface VerifyEmailProps {}

interface ISamlEmailOtpResponse {
  csrfToken: string;
}

interface ISamlAuthResponse {
  samlResponse: string;
  spPostUrl: string;
}

const VerifyEmail: React.FC<VerifyEmailProps> = () => {
  const [emailSubmitted, setEmailSubmitted] = React.useState<boolean>(false);
  const [isError, setIsError] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>("");
  const [successMessage, setSuccessMessage] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");
  const [otp, setOtp] = React.useState<string>("");
  const [csrfToken, setCsrfToken] = React.useState<string>("");
  const [alertModalOpen, setAlertModalOpen] = React.useState<boolean>(false);
  const [isLoading, setisLoading] = React.useState<boolean>(false);

  const { t } = useTranslation();

  React.useEffect(() => {}, [isLoading]);

  let handleEmailSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setSuccess(t("email.otp.sending.email"));
    sendEmailOTP();
  };

  let sendEmailOTP = () => {
    if (!validateEmail()) {
      setError(t("email.otp.invalid.email.address"));
      return;
    }
    // open overlay
    setisLoading(() => true);
    createHttpClient()
      .post<ISamlEmailOtpResponse>("/security/saml/generate/email-otp", {
        samlRequest: sessionStorage.getItem(SAML_REQUEST_KEY),
        email: email,
      })
      .then((resp) => {
        setCsrfToken(resp.data.csrfToken);
        setSuccess(t("email.otp.send.success"));
        //close overlay, then open the modal
        setisLoading(() => false);
        setAlertModalOpen(() => true);
        setEmailSubmitted(true);
      })
      .catch((err) => {
        setisLoading(() => false);
        setError(t("email.otp.send.error"));
      });
  };

  let handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  let handleOtpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOtp(e.target.value);
  };

  let setSuccess = (successMessage: string) => {
    setIsError(false);
    setErrorMessage("");
    setSuccessMessage(successMessage);
  };

  let setError = (errorMessage: string) => {
    setErrorMessage(errorMessage);
    setIsError(true);
  };

  let handleOtpSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (!validateOtp()) {
      setError(t("email.otp.invalid.otp"));
      return;
    }
    //open overlay
    setisLoading(() => true);
    createHttpClient()
      .post<ISamlAuthResponse>("/security/saml/verify/email-otp", {
        samlRequest: sessionStorage.getItem(SAML_REQUEST_KEY),
        email: email,
        otp: otp,
        csrfToken: csrfToken,
      })
      .then((resp) => {
        const samlResponse = resp.data.samlResponse;
        const spPostUrl = resp.data.spPostUrl;
        const samlRelayState = sessionStorage.getItem(SAML_RELAYSTATE_KEY)!;

        const form = document.createElement("form");
        form.setAttribute("method", "post");
        form.setAttribute("action", spPostUrl);

        const samlResponseInput = document.createElement("input");
        samlResponseInput.setAttribute("type", "hidden");
        samlResponseInput.setAttribute("name", "SAMLResponse");
        samlResponseInput.setAttribute("value", samlResponse);
        form.appendChild(samlResponseInput);

        const relayStateInput = document.createElement("input");
        relayStateInput.setAttribute("type", "hidden");
        relayStateInput.setAttribute("name", "RelayState");
        relayStateInput.setAttribute("value", samlRelayState);
        form.appendChild(relayStateInput);

        document.body.appendChild(form);

        sessionStorage.removeItem(SAML_REQUEST_KEY);
        sessionStorage.removeItem(SAML_RELAYSTATE_KEY);

        form.submit();
        //close overlay
        setisLoading(() => false);
      })
      .catch((err) => {
        if (
          err.response?.status === 400 &&
          err.response?.data?.message === "otp is not a match"
        ) {
          //close overlay
          setisLoading(() => false);
          setError(t("email.otp.mismatch,otp"));
        } else {
          //close overlay
          setisLoading(() => false);
          setError(t("email.otp.send.error"));
        }
      });
  };

  let handleResendOtp = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setSuccess(t("email.otp.resending.email"));
    sendEmailOTP();
  };

  let handleBackButton = () => {
    setSuccess("");
    setEmailSubmitted(false);
  };

  let validateEmail = () => {
    const re = new RegExp(
      "^(?=.{1,64}@)[a-zA-Z0-9_+&*-]+(?:\\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,}$"
    );
    return re.test(email);
  };

  let validateOtp = () => {
    const re = new RegExp("^[0-9][0-9][0-9][0-9][0-9][0-9]$");
    return re.test(otp);
  };

  const showErrorMessage = (msg: any, name: any) => {
    return (
      <div className="tds-landing-request-access-form-error">
        <ErrorMessage message={msg} fieldId={name} />
      </div>
    );
  };

  return (
    <div className="tds-verify-container">
      <HeaderNavUnAuthenticated />
      <div className="tds-homepage-container registration">
        <div className="tds-container">
          <div className="tds-divider-space-10">&nbsp;</div>
          <h2 className="tds-verify-title">{t("email.otp.heading")}</h2>
          <div className="tds-verify-form-container">
            <div className="tds-verify-form">
              {!emailSubmitted && (
                <form
                  className="tds-landing-request-access-form-container"
                  onSubmit={handleEmailSubmit}
                >
                  <div className="tds-landing-form-group tds-landing-form-small-field form-group td-form-group-padding tds-landing-form-required">
                    <label htmlFor="email">{t("email.otp.email.input")}</label>
                    <input
                      type="text"
                      id="email"
                      name="email"
                      value={email}
                      onChange={handleEmailChange}
                      className={`form-control ${isError ? "is-invalid" : ""}`}
                      aria-required="true"
                      aria-describedby="email-required"
                    />
                    {isError && showErrorMessage(errorMessage, email)}
                  </div>
                  <div className="tds-landing-form-buttons tds-verify-btn-center">
                    <button
                      className="btn td-btn-primary-light tds-verify-button"
                      type="submit"
                    >
                      {t("email.otp.email.submit")}
                    </button>
                  </div>
                </form>
              )}

              {emailSubmitted && (
                <form
                  onSubmit={handleOtpSubmit}
                  className="tds-landing-request-access-form-container"
                >
                  <div className="tds-landing-form-group tds-landing-form-small-field form-group td-form-group-padding tds-landing-form-required">
                    <label htmlFor="otp">{t("email.otp.input")}</label>
                    <br />
                    <input
                      type="text"
                      id="otp"
                      name="otp"
                      onChange={handleOtpChange}
                      className={`form-control ${isError ? "is-invalid" : ""}`}
                      aria-required="true"
                      aria-describedby="email-required"
                    />
                    {isError && showErrorMessage(errorMessage, email)}

                    <div className="tds-landing-form-buttons tds-verify-btn-container">
                      <button
                        className="tds-verify-button btn td-btn-secondary-clear tds-verify-button"
                        onClick={handleResendOtp}
                      >
                        {t("email.otp.resend")}
                      </button>
                      <button
                        className="btn td-btn-primary-light tds-verify-button"
                        onClick={handleOtpSubmit}
                      >
                        {t("email.otp.verify")}
                      </button>
                    </div>
                    <div className="tds-landing-form-buttons tds-verify-btn-center">
                      <button
                        className="btn td-btn-secondary-clear tds-verify-button"
                        onClick={handleBackButton}
                      >
                        {t("email.otp.back")}
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
          <Footer />
          <Modal
            id="registration-alert-modal-01"
            className=""
            isOpen={alertModalOpen}
            showHideModal={() => setAlertModalOpen(!alertModalOpen)}
            actionButtonClickHandler={() => setAlertModalOpen(!alertModalOpen)}
            actionButtonText="OK"
          >
            <div>{successMessage}</div>
          </Modal>
        </div>
      </div>

      {isLoading && (
        <div className="tds-verify-overlay">
          <div className="tds-verify-spinner"></div>
        </div>
      )}
    </div>
  );
};

export default VerifyEmail;
