import * as React from "react";
import { ChangeEvent, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select, { SelectOption } from "../../Common/Select/Select";
import { useAuth } from "../../../auth/AuthProvider";
import {
  ReactSelectDesktopStyles,
  ReactSelectDesktopStylesError,
} from "../../../constants/reactSelect";
import ErrorMessage from "../../Common/ErrorMessage/ErrorMessage";
import {
  SELECT,
  TD_EMPLOYEE_LOB,
  TD_RELATIONSHIP_OPTIONS,
} from "./RegistrationRequestConstants";
import HeaderNavUnAuthenticated from "../HeaderNav/HeaderNavUnAuthenticated";
import Footer from "../Footer/Footer";
import {
  EMAIL_REGEX,
  handleDropdownValues,
  NAME_REGEX,
  setSelectedOption,
} from "../../../utils/Utils";
import {
  registerAppAccessRequests,
  registerUser,
} from "../../../services/RegistrationService";
import { useNavigate } from "react-router";
import {
  LOGIN,
  REQUEST_ACCESS_CONFIRMATION,
} from "../../../constants/routePaths";
import Registration from "./RegistrationData";
import {
  INCORRECT_EMAIL_MSG,
  MESSAGE,
  NUMBER_OR_SPECIAL_CHARS_NOT_ALLOWED,
  PHONE_NUMBER_INVALID_MESSAGE,
} from "../../Common/ErrorMessage/ErrorMessagesConstants";
import "./RegistrationRequest.scss";
import {
  Application,
  ApplicationStatus,
  FieldType,
  HomePageData,
} from "../../../data/apiData";
import {
  createHttpClient,
  createSecureHttpClient,
} from "../../../utils/HttpClient";
import {
  LANDING_PAGE_AUTHENTICATED_DATA_URL,
  LANDING_PAGE_PUBLIC_DATA_URL,
} from "../../../constants/urls";
import { useTranslation } from "react-i18next";
import {
  getCountries,
  getPhoneInputCountries,
} from "../../../services/CountryService";
import AppAccessRequest from "./AppAccessRequest";
import CustomCheckbox from "../../Common/customCheckbox/CustomCheckbox";
import { ApplicationDto, createAppDto } from "./AppAccessRequestUtils";
import PhoneInput, { formatPhoneNumber } from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input/max";
import "react-phone-number-input/style.css";
import fr from "react-phone-number-input/locale/fr.json";
import en from "react-phone-number-input/locale/en.json";
import { useLocation } from "react-router-dom";
import HeaderNav from "../HeaderNav/HeaderNav";
import { homePageContext } from "../../../pages/Home";
import flags from "react-phone-number-input/flags";
import Modal from "../../Common/modal/Modal";
import RegistrationConflictErrorMessage, {
  RegistrationConflictError,
} from "./RegistrationConflictErrorMessage";
import CustomTooltip from "../../Common/CustomTooltip/CustomTooltip";

const initialRegistrationData = {
  firstName: "",
  lastName: "",
  email: "",
  countryCode: "CA",
  telephoneNumber: "",
  institutionalCorporateSalesContact: null,
  tdEmployeeLob: null,
  acf2: null,
  companyName: null,
  occupation: null,
  companyAddress: null,
  country: null,
  provinceOrState: null,
  city: null,
  zipOrPostalCode: null,
  otherLob: null,
  apps: null,
  relationshipWithTd: null,
  salesContactEmail: "",
  salesContactPhone: "",
  compliance: false,
};

export enum RelationshipWithTd {
  EXISTING_TDS_CLIENT = "EXISTING_TDS_CLIENT",
  TD_EMPLOYEE = "TD_EMPLOYEE",
  OTHER = "OTHER",
}

const RegistrationRequest = () => {
  let { getToken, authenticated } = useAuth();
  const [homePageData, setHomePageData] = useState<HomePageData>();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [tdEmployeeLobObj, setTdEmployeeLobObj] = useState<SelectOption>(null!);
  const [countryCodeObj, setCountryCodeObj] = useState<SelectOption>(null!);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [isCountryCodeRequired, setCountryCodeRequired] = useState(false);
  const [isTdRelationshipRequired, setTdRelationshipRequired] = useState(false);
  const [tdRelationshipObj, setTdRelationshipObj] = useState<SelectOption>(
    null!
  );
  const [isTdEmployeeLobRequired, setTdEmployeeLobRequired] = useState(false);
  const [countryObj, setCountryObj] = useState<SelectOption>(null!);
  const [isCountryRequired, setCountryRequired] = useState(false);
  const [appDtoList, setAppDtoList] = useState<ApplicationDto[]>([]);
  const [isPhoneNumRequired, setPhoneNumRequired] = useState(false);
  const [isPhoneNumValid, setPhoneNumValid] = useState(true);
  const [isSalesContactPhoneValid, setSalesContactPhoneValid] = useState(true);
  const [submitErrorMessage, setSubmitErrorMessage] = useState<string>(null!);
  const [compliance, setCompliance] = useState(false);
  const [registrationData, setRegistrationData]: any = useState<Registration>(
    initialRegistrationData
  );
  const [apps, setApps] = useState<Application[]>([]);
  const [appDtoListValidated, setAppDtoListValidated] = useState(false);

  const [alertModelOpen, setAlertModalOpen] = useState(false);
  const [registrationErrorType, setRegistrationErrorType] =
    useState<RegistrationConflictError>();

  const isEnglish = () => {
    return i18n.language.startsWith("en");
  };

  const { state } = useLocation();

  useEffect(() => {
    const allApps = homePageData?.categories.flatMap((c) => c.applications);
    if (allApps) setApps(allApps);
  }, [homePageData]);

  useEffect(() => {
    if (state) {
      // @ts-ignore
      const { appName } = state;
      if (appName && apps) {
        const selectedApp = apps.find((a) => a.name === appName);
        if (selectedApp) handleAppSelected(selectedApp.id, true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apps, state]);

  const handleAppSelected = (appId: string, isChecked: boolean) => {
    if (isChecked && apps && apps.length) {
      // @ts-ignore
      const dto = createAppDto(apps.find((app) => app.id === appId));
      setAppDtoList([...appDtoList, dto]);
    } else {
      setAppDtoList([
        ...appDtoList.filter((application) => application.id !== appId),
      ]);
    }
  };

  const getAllApps = () => {
    const isAppSelected = (appId: string) => {
      return appDtoList.some((a) => a.id === appId);
    };

    const handleFieldValueChanged = (
      appId: string,
      fieldId: number,
      value: string | number | boolean | number[]
    ) => {
      const appDto = appDtoList.find((app) => app.id === appId);
      const appDtoIndex = appDtoList.findIndex((app) => app.id === appId);
      if (appDto) {
        appDtoList[appDtoIndex] = {
          id: appId,
          fields: [
            ...appDto.fields.filter((f) => f.id !== fieldId),
            { id: fieldId, value },
          ],
        };
        setAppDtoList([...appDtoList]);
      }
    };

    const isUserHasAccessToAppOrRequestInProcess = (app: Application) => {
      return app.userHasAccess || app.requestInProcess;
    };

    const getApps = () => {
      return apps
        ?.filter(
          (a) =>
            a.status === ApplicationStatus.FULLY_ONBOARDED &&
            !isUserHasAccessToAppOrRequestInProcess(a)
        )
        .map((app) => {
          return (
            <CustomCheckbox
              style={{ display: "inline-block", marginRight: "30px" }}
              key={"app_id_" + app.id}
              value={app.id}
              labelText={isEnglish() ? app.name : app.frenchName}
              selected={isAppSelected(app.id)}
              handleClicked={handleAppSelected}
            />
          );
        });
    };

    const getAppRequestForms = () => {
      return appDtoList
        .filter((app) => isAppSelected(app.id))
        .map((appDto) => {
          const app = apps.find((app) => app.id === appDto.id);
          return (
            app && (
              <AppAccessRequest
                key={"app_access_request_" + appDto.id}
                application={app}
                appDto={appDto}
                validated={appDtoListValidated}
                handleFieldValueChanged={handleFieldValueChanged}
              />
            )
          );
        });
    };

    const isAppsToShow = () => {
      return !!apps?.filter(
        (a) => a.status === ApplicationStatus.FULLY_ONBOARDED
      ).length;
    };

    return (
      <>
        {isAppsToShow() && (
          <>
            <div className="font-weight-bold" style={{ marginTop: "15px" }}>
              {t("app.access.request.service.desc")}
            </div>
            <div>{getApps()}</div>
            <div>{getAppRequestForms()}</div>
          </>
        )}
      </>
    );
  };

  const [dropdownValuesFlag, setDropdownValuesFlag]: any =
    useState<boolean>(false);

  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
    control,
  } = useForm({
    mode: "all",
    defaultValues: initialRegistrationData,
  });

  const handlePhoneNumberBlur = () => {
    !registrationData.telephoneNumber
      ? setPhoneNumRequired(true)
      : setPhoneNumRequired(false);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    let value = event.target.value;
    setRegistrationData({
      ...registrationData,
      [name]: value.trim() === "" ? null : value.trim(),
    });
  };

  const handleInputOnBlur = (event: ChangeEvent<HTMLInputElement>) => {
    handleInputChange(event);
    const name: any = event.target.name;
    if (event.target.value === "") {
      setTimeout(
        () =>
          setError(name, {
            type: "required",
            message: "" + showErrorMessage(t(MESSAGE), name + "-required"),
          }),
        200
      );
    }
  };

  const handleTdRelationship = (tdRelationship: any) => {
    setTdRelationshipObj!(
      setSelectedOption(
        handleDropdownValues(TD_RELATIONSHIP_OPTIONS, tdRelationship)
      )
    );
    registrationData.relationshipWithTd = tdRelationship;
    reset({
      ...registrationData,
      institutionalCorporateSalesContact: null,
      salesContactEmail: null,
      salesContactPhone: null,
      acf2: null,
      tdEmployeeLob: null,
      otherLob: null,
      companyName: null,
      occupation: null,
      companyAddress: null,
      country: null,
      provinceOrState: null,
      city: null,
      zipOrPostalCode: null,
    });
    setRegistrationData({
      ...registrationData,
      institutionalCorporateSalesContact: null,
      salesContactEmail: null,
      salesContactPhone: null,
      acf2: null,
      tdEmployeeLob: null,
      otherLob: null,
      companyName: null,
      occupation: null,
      companyAddress: null,
      country: null,
      provinceOrState: null,
      city: null,
      zipOrPostalCode: null,
    });
  };

  const handleTdRelationshipOnBlur = () => {
    tdRelationshipObj === null
      ? setTdRelationshipRequired(true)
      : setTdRelationshipRequired(false);
  };

  const handleTdEmployeeLob = (lob?: any) => {
    setTdEmployeeLobObj!(
      setSelectedOption(handleDropdownValues(TD_EMPLOYEE_LOB, lob))
    );
    registrationData.tdEmployeeLob = lob;
  };

  const handleTdEmployeeLobOnBlur = () => {
    tdEmployeeLobObj === null
      ? setTdEmployeeLobRequired(true)
      : setTdEmployeeLobRequired(false);
  };

  const handleCountryChange = (country: any) => {
    setCountryObj!(
      setSelectedOption(handleDropdownValues(getCountries(), country))
    );
    registrationData.country = country;
  };

  const handleCountryOnBlur = () => {
    countryObj === null ? setCountryRequired(true) : setCountryRequired(false);
  };

  const handleCountryCodeChange = (countryCode: any) => {
    setCountryCodeObj!(
      setSelectedOption(handleDropdownValues(getCountries(), countryCode))
    );
    registrationData.countryCode = countryCode;
  };

  const handleCountryCodeOnBlur = () => {
    countryCodeObj === null && !isCountryCodeRequired
      ? setCountryCodeRequired(true)
      : setCountryCodeRequired(false);
  };

  const handleComplianceCheckbox = (e: any) => {
    setCompliance(e.target.checked ? true : false);
  };

  useEffect(() => {
    document.title = t("browser.title.registration.page");
  }, [t]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (authenticated) {
          const response = await createSecureHttpClient(
            getToken()!
          ).get<HomePageData>(LANDING_PAGE_AUTHENTICATED_DATA_URL);
          setHomePageData(response.data);
        } else {
          const response = await createHttpClient().get<HomePageData>(
            LANDING_PAGE_PUBLIC_DATA_URL
          );
          setHomePageData(response.data);
        }
      } catch (e) {
        console.error(e);
      }
    };
    fetchData();
  }, [authenticated, getToken]);

  useEffect(() => {
    if (countryCodeObj !== null && tdRelationshipObj !== null) {
      if (
        registrationData.relationshipWithTd === RelationshipWithTd.OTHER ||
        registrationData.relationshipWithTd === RelationshipWithTd.TD_EMPLOYEE
      ) {
        if (countryObj !== null || tdEmployeeLobObj !== null) {
          setDropdownValuesFlag(true);
        } else {
          setDropdownValuesFlag(false);
        }
      } else {
        setDropdownValuesFlag(true);
      }
    } else {
      setDropdownValuesFlag(false);
    }
  }, [
    countryObj,
    countryCodeObj,
    tdRelationshipObj,
    tdEmployeeLobObj,
    registrationData.relationshipWithTd,
  ]);

  useEffect(() => {
    getCountries().forEach((countryCode) => {
      countryCode.label = t(
        "registration.countrycode." + countryCode.value.toLowerCase()
      );
    });
    setCountryCodeObj!(
      setSelectedOption(
        handleDropdownValues(getCountries(), registrationData.countryCode)
      )
    );
    getCountries().forEach((country) => {
      country.label = t("registration.country." + country.value.toLowerCase());
    });
    setCountryObj!(
      setSelectedOption(
        handleDropdownValues(getCountries(), registrationData.country)
      )
    );

    TD_RELATIONSHIP_OPTIONS.forEach((relOption) => {
      relOption.label = t(
        "registration.relationship." +
          relOption.value.toLowerCase().replaceAll("_", ".")
      );
    });

    setTdRelationshipObj!(
      setSelectedOption(
        handleDropdownValues(
          TD_RELATIONSHIP_OPTIONS,
          registrationData.relationshipWithTd
        )
      )
    );

    TD_EMPLOYEE_LOB.forEach((empLob) => {
      empLob.label = t(
        "registration.emp.lob." +
          empLob.value.toLowerCase().replaceAll("_", ".")
      );
    });
    setTdEmployeeLobObj!(
      setSelectedOption(
        handleDropdownValues(TD_EMPLOYEE_LOB, registrationData.tdEmployeeLob)
      )
    );
  }, [t, registrationData]);

  const validateAppDtoList = () => {
    return appDtoList.every((appDto) => {
      const app = apps?.find((a) => a.id === appDto.id);
      return app?.fields?.every((field) => {
        if (field.required) {
          const fieldDto = appDto.fields.find((f) => f.id === field.id);
          if (field.type === FieldType.MULTI_SELECT) {
            return fieldDto?.value && (fieldDto?.value as number[]).length;
          }
          return !!fieldDto?.value;
        }
        return true;
      });
    });
  };

  const onSubmit = () => {
    setAppDtoListValidated(true);
    if (!authenticated) {
      if (dropdownValuesFlag && validateAppDtoList()) {
        registrationData.apps = appDtoList;
        setDisableSubmit(true);
        /**initial value format for phone number is in international format
         * we format the number to national format here for two-factor-authentication API
         */
        const phoneNumberAfterFormat = formatPhoneNumber(
          registrationData.telephoneNumber
        ).replace(/\D/g, "");
        const SalesContactPhoneAfterFormat = registrationData.salesContactPhone
          ? formatPhoneNumber(registrationData.salesContactPhone).replace(
              /\D/g,
              ""
            )
          : null;

        registerUser({
          ...registrationData,
          telephoneNumber: phoneNumberAfterFormat,
          salesContactPhone: SalesContactPhoneAfterFormat,
        })
          .then(() => {
            navigate(REQUEST_ACCESS_CONFIRMATION);
            setSubmitErrorMessage(null!);
            setDisableSubmit(false);
          })
          .catch((err) => {
            if (err && err.response && err.response.status === 409) {
              const message = err.response.data.message;
              setRegistrationErrorType(message);
              setAlertModalOpen(true);
            }
            setDisableSubmit(false);
          });
      }
    } else {
      if (appDtoList && appDtoList.length && validateAppDtoList()) {
        setDisableSubmit(true);
        registerAppAccessRequests(getToken()!, { apps: appDtoList })
          .then(() => {
            navigate(REQUEST_ACCESS_CONFIRMATION);
            setDisableSubmit(false);
          })
          .catch((err) => {
            if (err && err.response && err.response.status === 409) {
              const message = err.response.data.message;
              setRegistrationErrorType(message);
              setAlertModalOpen(true);
            }
            setDisableSubmit(false);
          });
      }
    }
  };

  const handleCancel = () => {
    navigate(LOGIN);
  };

  // prevent the form error from pushing the forms field up
  const showErrorMessage = (msg: any, name: any) => {
    return (
      <div className="tds-landing-request-access-form-error">
        <ErrorMessage message={msg} fieldId={name} />
      </div>
    );
  };

  const checkAllDropDownValues = () => {
    handleCountryCodeOnBlur();
    handleCountryOnBlur();
    handlePhoneNumberBlur();
    handleTdRelationshipOnBlur();
    handleTdEmployeeLobOnBlur();
  };

  // @ts-ignore
  return (
    <>
      {authenticated && homePageData ? (
        <homePageContext.Provider value={homePageData}>
          <HeaderNav />
        </homePageContext.Provider>
      ) : (
        <HeaderNavUnAuthenticated />
      )}
      <div className="tds-homepage-container registration">
        <div className="tds-container">
          <div className="tds-divider-space-10">&nbsp;</div>
          <h2>
            {authenticated
              ? t("registration.app-access-request.title")
              : t("registration.title")}
          </h2>
          <p>
            {authenticated
              ? t("registration.app-access-request.description")
              : t("registration.requestaccess.text1")}
            <br />
            {/* <br />
            {t("registration.requestaccess.text2")} */}
            {submitErrorMessage && (
              <ErrorMessage
                message={submitErrorMessage}
                fieldId={"save-message-id"}
              />
            )}
          </p>
          <form
            onSubmit={handleSubmit(onSubmit, () =>
              setAppDtoListValidated(true)
            )}
            className="tds-landing-request-access-form-container"
          >
            {!authenticated && (
              <>
                <div className="tds-landing-request-access-form-grid-container">
                  <div className="tds-landing-form-group tds-landing-form-small-field form-group td-form-group-padding tds-landing-form-required">
                    <label htmlFor="firstName">
                      {t("registration.first.name")}
                    </label>
                    <input
                      {...register("firstName", {
                        required: true,
                        pattern: NAME_REGEX,
                        setValueAs: (v) => (v !== null ? v.trim() : v),
                      })}
                      name="firstName"
                      type="text"
                      maxLength={25}
                      className={`form-control ${
                        errors?.firstName ? "is-invalid" : ""
                      }`}
                      id="firstName"
                      onBlur={handleInputOnBlur}
                      autoFocus={true}
                      aria-required="true"
                      aria-invalid={
                        errors.firstName?.type === "pattern" ? "true" : "false"
                      }
                      aria-describedby="firstName-required firstName-invalid"
                    />
                    {errors.firstName?.type === "required" &&
                      showErrorMessage(t(MESSAGE), "firstName-required")}
                    {errors.firstName?.type === "pattern" &&
                      showErrorMessage(
                        t(NUMBER_OR_SPECIAL_CHARS_NOT_ALLOWED),
                        "firstName-invalid"
                      )}
                  </div>
                  <div className="tds-landing-form-group tds-landing-form-small-field form-group td-form-group-padding tds-landing-form-required">
                    <label htmlFor="lastName">
                      {t("registration.last.name")}
                    </label>
                    <input
                      {...register("lastName", {
                        required: true,
                        pattern: NAME_REGEX,
                        setValueAs: (v) => (v !== null ? v.trim() : v),
                      })}
                      name="lastName"
                      type="text"
                      maxLength={25}
                      className={`form-control ${
                        errors?.lastName ? "is-invalid" : ""
                      }`}
                      id="lastName"
                      onChange={handleInputChange}
                      aria-required="true"
                      aria-invalid={
                        errors.lastName?.type === "pattern" ? "true" : "false"
                      }
                      aria-describedby="lastName-required lastName-invalid"
                    />
                    {errors.lastName?.type === "required" &&
                      showErrorMessage(t(MESSAGE), "lastName-required")}
                    {errors.lastName?.type === "pattern" &&
                      showErrorMessage(
                        t(NUMBER_OR_SPECIAL_CHARS_NOT_ALLOWED),
                        "lastName-invalid"
                      )}
                  </div>
                  <div className="tds-landing-form-group tds-landing-form-small-field form-group td-form-group-padding tds-landing-form-required">
                    <label htmlFor="email">{t("registration.email")}</label>
                    <input
                      {...register("email", {
                        required: true,
                        pattern: EMAIL_REGEX,
                        setValueAs: (v) => (v !== null ? v.trim() : v),
                      })}
                      name="email"
                      type="text"
                      maxLength={256}
                      className={`form-control ${
                        errors?.email ? "is-invalid" : ""
                      }`}
                      id="email"
                      onChange={handleInputChange}
                      aria-required="true"
                      aria-invalid={
                        errors.email?.type === "pattern" ? "true" : "false"
                      }
                      aria-describedby="email-required email-invalid"
                    />
                    {errors.email?.type === "required" &&
                      showErrorMessage(t(MESSAGE), "email-required")}
                    {errors.email?.type === "pattern" &&
                      showErrorMessage(t(INCORRECT_EMAIL_MSG), "email-invalid")}
                  </div>
                  <div className="tds-landing-form-group tds-landing-form-small-field form-group td-form-group-padding tds-landing-form-required">
                    <label htmlFor="telephoneNumber">
                      {t("registration.mobile.phone.number")}
                    </label>
                    <span className="descriptor-text">
                      <CustomTooltip
                        id="phone-number-tooltip"
                        descriptorText="phone.number.descriptor.text"
                        toolTipMessage="phone.number.tooltip.message"
                        toolTipDescriptorClass={
                          isEnglish()
                            ? "tooltip-descriptor-text"
                            : "tooltip-descriptor-text tooltip-descriptor-left-padding-for-fr"
                        }
                        containerClassName="tooltip-msg-box"
                      />
                    </span>
                    <Controller
                      name="telephoneNumber"
                      control={control}
                      rules={{
                        validate: (value) => isValidPhoneNumber(value),
                        required: true,
                      }}
                      render={({ field: { onChange } }) => (
                        <PhoneInput
                          flags={flags}
                          name="telephoneNumber"
                          id="telephoneNumber"
                          initialValueFormat="national"
                          defaultCountry="CA"
                          international={true}
                          withCountryCallingCode={true}
                          countryCallingCodeEditable={false}
                          addInternationalOption={false}
                          limitMaxLength={true}
                          onCountryChange={handleCountryCodeChange}
                          onBlur={(event) => {
                            handlePhoneNumberBlur();
                            let number = event.target as HTMLInputElement;
                            number.value && isValidPhoneNumber(number.value)
                              ? setPhoneNumValid(true)
                              : setPhoneNumValid(false);
                          }}
                          countries={getPhoneInputCountries()}
                          labels={isEnglish() ? en : fr}
                          countryOptionsOrder={["CA", "US", "GB", "IE", "SG"]}
                          focusInputOnCountrySelection={true}
                          aria-required="true"
                          aria-describedby="telephoneNumber-required telephoneNumber-invalid"
                          aria-invalid={
                            errors.telephoneNumber?.type === "pattern"
                              ? "true"
                              : "false"
                          }
                          className={
                            (isPhoneNumRequired &&
                              !registrationData.telephoneNumber) ||
                            !isPhoneNumValid
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          onChange={(val) => {
                            onChange(val);
                            setRegistrationData({
                              ...registrationData,
                              telephoneNumber: val,
                            });
                          }}
                        />
                      )}
                    />
                    {isPhoneNumRequired &&
                      !registrationData.telephoneNumber &&
                      showErrorMessage(t(MESSAGE), "telephoneNumber-required")}
                    {registrationData.telephoneNumber &&
                      !isPhoneNumValid &&
                      showErrorMessage(
                        t(PHONE_NUMBER_INVALID_MESSAGE),
                        "telephoneNumber-invalid"
                      )}
                  </div>
                </div>
                <div className="tds-landing-request-access-form-grid-container">
                  <div
                    className={`tds-landing-form-group tds-landing-form-small-field form-group td-form-group-padding tds-landing-form-required &&  
                  ${
                    registrationData.relationshipWithTd ===
                      RelationshipWithTd.EXISTING_TDS_CLIENT &&
                    !isEnglish() &&
                    "request-access-relationship-margin-top"
                  }`}
                  >
                    <label id="tdRelationship" htmlFor="tdRelationship">
                      {t("registration.relationship.with.td")}
                    </label>
                    <div
                      className="tds-td-employee-form-dropdown"
                      aria-labelledby="tdRelationship"
                      aria-required="true"
                      onMouseUp={handleTdRelationshipOnBlur}
                    >
                      <Select
                        name="tdRelationship"
                        value={tdRelationshipObj}
                        options={TD_RELATIONSHIP_OPTIONS}
                        onChange={handleTdRelationship}
                        onBlur={handleTdRelationshipOnBlur}
                        isClearable={true}
                        styles={
                          isTdRelationshipRequired && !tdRelationshipObj
                            ? ReactSelectDesktopStylesError
                            : ReactSelectDesktopStyles
                        }
                        placeholder={t(SELECT)}
                      />
                      {isTdRelationshipRequired &&
                        !tdRelationshipObj &&
                        showErrorMessage(t(MESSAGE), "")}
                    </div>
                  </div>
                  {registrationData.relationshipWithTd ===
                    RelationshipWithTd.EXISTING_TDS_CLIENT && (
                    <>
                      <div className="tds-landing-form-group tds-landing-form-small-field form-group td-form-group-padding tds-landing-form-required">
                        <label htmlFor="institutionalCorporateSalesContact">
                          {t(
                            "registration.institutional.or.corporate.sales.contact"
                          )}
                        </label>
                        <input
                          {...register("institutionalCorporateSalesContact", {
                            required: true,
                            setValueAs: (v) => (v !== null ? v.trim() : v),
                          })}
                          name="institutionalCorporateSalesContact"
                          type="text"
                          maxLength={65}
                          className={`form-control ${
                            errors?.institutionalCorporateSalesContact
                              ? "is-invalid"
                              : ""
                          }`}
                          id="institutionalCorporateSalesContact"
                          onChange={handleInputChange}
                          aria-required="true"
                          aria-describedby="institutionalCorporateSalesContact-required"
                        />
                        {errors.institutionalCorporateSalesContact?.type ===
                          "required" &&
                          showErrorMessage(
                            t(MESSAGE),
                            "institutionalCorporateSalesContact-required"
                          )}
                      </div>

                      <div className="tds-landing-form-group tds-landing-form-small-field form-group td-form-group-padding">
                        <label htmlFor="salesContactEmail">
                          {t("registration.sales.contact.email")}
                        </label>
                        <input
                          {...register("salesContactEmail", {
                            pattern: EMAIL_REGEX,
                            setValueAs: (v) => (v !== null ? v.trim() : v),
                          })}
                          name="salesContactEmail"
                          type="text"
                          maxLength={65}
                          className={`form-control ${
                            errors?.salesContactEmail ? "is-invalid" : ""
                          }`}
                          id="salesContactEmail"
                          onChange={handleInputChange}
                          aria-invalid={
                            errors.salesContactEmail?.type === "pattern"
                              ? "true"
                              : "false"
                          }
                          aria-describedby="salesContactEmail-invalid"
                        />
                        {errors.salesContactEmail?.type === "pattern" &&
                          showErrorMessage(
                            t(INCORRECT_EMAIL_MSG),
                            "salesContactEmail-invalid"
                          )}
                      </div>
                      <div className="tds-landing-form-group tds-landing-form-small-field form-group td-form-group-padding">
                        <label htmlFor="salesContactPhone">
                          {t("registration.sales.contact.phone")}
                        </label>
                        <Controller
                          name="salesContactPhone"
                          control={control}
                          rules={{
                            required: false,
                            validate: (value) =>
                              value ? isValidPhoneNumber(value) : true,
                          }}
                          render={({ field: { onChange } }) => (
                            <PhoneInput
                              flags={flags}
                              name="salesContactPhone"
                              id="salesContactPhone"
                              initialValueFormat="national"
                              defaultCountry="CA"
                              international={true}
                              withCountryCallingCode={true}
                              countryCallingCodeEditable={false}
                              addInternationalOption={false}
                              limitMaxLength={true}
                              onCountryChange={handleCountryCodeChange}
                              onBlur={(event) => {
                                let number = event.target as HTMLInputElement;
                                number.value && isValidPhoneNumber(number.value)
                                  ? setSalesContactPhoneValid(true)
                                  : setSalesContactPhoneValid(false);
                              }}
                              countries={getPhoneInputCountries()}
                              labels={isEnglish() ? en : fr}
                              countryOptionsOrder={[
                                "CA",
                                "US",
                                "GB",
                                "IE",
                                "SG",
                              ]}
                              focusInputOnCountrySelection={true}
                              aria-describedby="salesContactPhone-invalid"
                              aria-invalid={
                                errors.salesContactPhone?.type === "pattern"
                                  ? "true"
                                  : "false"
                              }
                              className={
                                registrationData.salesContactPhone !==
                                  undefined &&
                                registrationData.salesContactPhone !== "" &&
                                registrationData.salesContactPhone !== null &&
                                !isSalesContactPhoneValid
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                              onChange={(val) => {
                                onChange(val);
                                setRegistrationData({
                                  ...registrationData,
                                  salesContactPhone: val,
                                });
                              }}
                            />
                          )}
                        />
                        {registrationData.salesContactPhone !== undefined &&
                          registrationData.salesContactPhone !== "" &&
                          registrationData.salesContactPhone !== null &&
                          !isSalesContactPhoneValid &&
                          showErrorMessage(
                            t(PHONE_NUMBER_INVALID_MESSAGE),
                            "salesContactPhone-invalid"
                          )}
                      </div>
                    </>
                  )}
                  {registrationData.relationshipWithTd ===
                    RelationshipWithTd.TD_EMPLOYEE && (
                    <>
                      <div className="tds-landing-form-group tds-landing-form-small-field form-group td-form-group-padding tds-landing-form-required">
                        <label htmlFor="acf2">
                          {t("registration.td.employee.acf2.id")}
                        </label>
                        <input
                          {...register("acf2", {
                            required: true,
                            setValueAs: (v) => (v !== null ? v.trim() : v),
                          })}
                          name="acf2"
                          type="text"
                          maxLength={25}
                          className={`form-control ${
                            errors?.acf2 ? "is-invalid" : ""
                          }`}
                          id="acf2"
                          aria-required="true"
                          aria-describedby="acf2-required"
                          onChange={handleInputChange}
                        />
                        {errors.acf2?.type === "required" &&
                          showErrorMessage(t(MESSAGE), "acf2-required")}
                      </div>
                      <div className="tds-landing-form-group tds-landing-form-small-field form-group td-form-group-padding tds-landing-form-required">
                        <label id="tdEmployeeLob" htmlFor="tdEmployeeLob">
                          {t("registration.td.employee.lob")}
                        </label>
                        <div
                          className="tds-td-employee-form-dropdown"
                          aria-labelledby="tdEmployeeLob"
                          aria-required="true"
                          onMouseUp={handleTdEmployeeLobOnBlur}
                        >
                          <Select
                            name="tdEmployeeLob"
                            value={tdEmployeeLobObj}
                            options={TD_EMPLOYEE_LOB}
                            onChange={handleTdEmployeeLob}
                            onBlur={handleTdEmployeeLobOnBlur}
                            isClearable={true}
                            styles={
                              isTdEmployeeLobRequired && !tdEmployeeLobObj
                                ? ReactSelectDesktopStylesError
                                : ReactSelectDesktopStyles
                            }
                            placeholder={t(SELECT)}
                          />

                          {isTdEmployeeLobRequired &&
                            !tdEmployeeLobObj &&
                            showErrorMessage(t(MESSAGE), "")}
                        </div>
                      </div>
                    </>
                  )}
                  {registrationData.relationshipWithTd ===
                    RelationshipWithTd.OTHER && (
                    <>
                      <div className="tds-landing-form-group tds-landing-form-small-field form-group td-form-group-padding tds-landing-form-required">
                        <label htmlFor="companyName">
                          {t("registration.company.name")}
                        </label>
                        <input
                          {...register("companyName", {
                            required: true,
                            setValueAs: (v) => (v !== null ? v.trim() : v),
                          })}
                          name="companyName"
                          type="text"
                          maxLength={65}
                          className={`form-control ${
                            errors?.companyName ? "is-invalid" : ""
                          }`}
                          id="companyName"
                          aria-required="true"
                          aria-describedby="companyName-required"
                          onChange={handleInputChange}
                        />
                        {errors.companyName?.type === "required" &&
                          showErrorMessage(t(MESSAGE), "companyName-required")}
                      </div>
                      <div className="tds-landing-form-group tds-landing-form-small-field form-group td-form-group-padding">
                        <label htmlFor="occupation">
                          {t("registration.occupation")}
                        </label>
                        <input
                          {...register("occupation")}
                          name="occupation"
                          type="text"
                          maxLength={65}
                          className="form-control"
                          id="occupation"
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="tds-landing-form-group tds-landing-form-small-field form-group td-form-group-padding">
                        <label htmlFor="companyAddress">
                          {t("registration.company.address")}
                        </label>
                        <input
                          {...register("companyAddress")}
                          name="companyAddress"
                          type="text"
                          maxLength={65}
                          className="form-control"
                          id="companyAddress"
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="tds-landing-form-group tds-landing-form-small-field form-group td-form-group-padding tds-landing-form-required">
                        <label id="country" htmlFor="country">
                          {t("registration.country")}
                        </label>
                        <div
                          className="tds-td-employee-form-dropdown"
                          aria-labelledby="country"
                          aria-required="true"
                          onMouseUp={handleCountryOnBlur}
                        >
                          <Select
                            name="country"
                            value={countryObj}
                            options={getCountries()}
                            onChange={handleCountryChange}
                            onBlur={handleCountryOnBlur}
                            isClearable={true}
                            styles={
                              isCountryRequired && !countryObj
                                ? ReactSelectDesktopStylesError
                                : ReactSelectDesktopStyles
                            }
                            placeholder={t(SELECT)}
                          />
                          {isCountryRequired &&
                            !countryObj &&
                            showErrorMessage(t(MESSAGE), "")}
                        </div>
                      </div>
                      <div className="tds-landing-form-group tds-landing-form-small-field form-group td-form-group-padding tds-landing-form-required">
                        <label htmlFor="provinceOrState">
                          {t("registration.province/state")}
                        </label>
                        <div className="tds-td-employee-form-four-column">
                          <input
                            {...register("provinceOrState", {
                              required: true,
                              setValueAs: (v) => (v !== null ? v.trim() : v),
                            })}
                            name="provinceOrState"
                            type="text"
                            maxLength={65}
                            className={`form-control ${
                              errors?.provinceOrState ? "is-invalid" : ""
                            }`}
                            id="provinceOrState"
                            aria-required="true"
                            aria-describedby="provinceOrState-required"
                            onChange={handleInputChange}
                          />
                          {errors.provinceOrState?.type === "required" &&
                            showErrorMessage(
                              t(MESSAGE),
                              "provinceOrState-required"
                            )}
                        </div>
                      </div>
                      <div className="tds-landing-form-group tds-landing-form-small-field form-group td-form-group-padding tds-landing-form-required">
                        <label htmlFor="city">{t("registration.city")}</label>
                        <div className="tds-td-employee-form-four-column">
                          <input
                            {...register("city", {
                              required: true,
                              setValueAs: (v) => (v !== null ? v.trim() : v),
                            })}
                            name="city"
                            type="text"
                            maxLength={65}
                            className={`form-control ${
                              errors?.city ? "is-invalid" : ""
                            }`}
                            id="city"
                            aria-required="true"
                            aria-describedby="city-required"
                            onChange={handleInputChange}
                          />
                          {errors.city?.type === "required" &&
                            showErrorMessage(t(MESSAGE), "city-required")}
                        </div>
                      </div>
                      <div className="tds-landing-form-group tds-landing-form-small-field form-group td-form-group-padding tds-landing-form-required">
                        <label htmlFor="zipOrPostalCode">
                          {t("registration.zip/postal.code")}
                        </label>
                        <input
                          {...register("zipOrPostalCode", {
                            required: true,
                            setValueAs: (v) => (v !== null ? v.trim() : v),
                          })}
                          name="zipOrPostalCode"
                          type="text"
                          maxLength={65}
                          className={`form-control ${
                            errors?.zipOrPostalCode ? "is-invalid" : ""
                          }`}
                          id="zipOrPostalCode"
                          aria-required="true"
                          aria-describedby="zipOrPostalCode-required"
                          onChange={handleInputChange}
                        />
                        {errors.zipOrPostalCode?.type === "required" &&
                          showErrorMessage(
                            t(MESSAGE),
                            "zipOrPostalCode-required"
                          )}
                      </div>
                    </>
                  )}
                </div>

                <div>
                  {registrationData.tdEmployeeLob ===
                    RelationshipWithTd.OTHER && (
                    <div className="tds-landing-form-group tds-landing-form-small-field form-group td-form-group-padding">
                      <label htmlFor="otherLob">
                        {t("registration.specify.your.line.of.business")}
                      </label>
                      <input
                        name="otherLob"
                        type="text"
                        maxLength={65}
                        className="form-control"
                        onChange={handleInputChange}
                        id="otherLob"
                      />
                    </div>
                  )}
                </div>
              </>
            )}
            <div style={{ marginTop: "10px" }}>{getAllApps()}</div>
            {registrationData.relationshipWithTd ===
              RelationshipWithTd.OTHER && (
              <>
                <div className="form-check form-check-inline tds-landing-form-group tds-landing-form-required registration-disclaimer">
                  <input
                    {...register("compliance", {
                      required: true,
                      setValueAs: (v) => (v !== null ? v : false),
                    })}
                    name="compliance"
                    type="checkbox"
                    id="compliance"
                    onChange={(e) => handleComplianceCheckbox(e)}
                  />
                  <label htmlFor="compliance"></label>
                  <span className="registration-disclaimer-padding">
                    {t("registration.disclaimer")}
                    <a
                      href={t("registration.disclaimer.link")}
                      title={t("registration.disclaimer.link.text")}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t("registration.disclaimer.link.text")}
                    </a>
                  </span>
                  {errors.compliance &&
                    !compliance &&
                    showErrorMessage(t(MESSAGE), "compliance-required")}
                </div>
              </>
            )}
            <div className="tds-landing-form-buttons">
              <button
                className="btn td-btn-secondary-clear"
                onClick={handleCancel}
              >
                {t("registration.cancel")}
              </button>
              <button
                className="btn td-btn-primary-light"
                type="submit"
                onClick={() => checkAllDropDownValues()}
                disabled={disableSubmit}
              >
                {t("registration.submit")}
              </button>
            </div>
          </form>
          <Footer />
          <Modal
            id="registration-alert-modal-01"
            className="registration-error-modal"
            isOpen={alertModelOpen}
            showHideModal={() => setAlertModalOpen(!alertModelOpen)}
            actionButtonClickHandler={() => setAlertModalOpen(!alertModelOpen)}
            actionButtonText="OK"
          >
            {registrationErrorType && (
              <RegistrationConflictErrorMessage
                error={registrationErrorType!}
              />
            )}
          </Modal>
        </div>
      </div>
    </>
  );
};

export default RegistrationRequest;
