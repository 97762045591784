import i18next from "i18next";

export const NAME_REGEX = /^[a-zA-ZÀ-ÿ]+[a-zA-ZÀ-ÿ '-]*$/;
export const EMAIL_REGEX =
  /^(?=.{1,64}@)[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;
export const NUMBER_REGEX = /^[0-9 ]+$/;
export const WHITE_SPACE_REMOVAL_REGEX = /\s+/g;

export const isValidValue = (value: any, regex: any) => {
  return !regex.test(value);
};

export const isNotEmpty = (str: any) => {
  return str !== undefined && str !== null && str.toString().trim().length > 0;
};

export const isEmpty = (str: any) => {
  return !isNotEmpty(str);
};

export const isNotEmptyArray = (array: string | any[]) => {
  return array && array.length !== undefined && array.length > 0;
};

export const isEmptyArray = (array: string | any[]) => {
  return !isNotEmptyArray(array);
};

export const isNotEmptyStringArray = (array: any[]) => {
  if (isNotEmptyArray(array)) {
    if (array.filter((value) => isNotEmpty(value)).length > 0) return true;
  }
  return false;
};

export const handleDropdownValues = (options: any[], code: any) => {
  const option = options.filter((option) => option.value === code);
  return option[0]?.label;
};

export const setSelectedOption = (option: any) => {
  return option
    ? {
        value: option,
        label: option,
      }
    : null!;
};

export const removeWhiteSpace = (value: string) => {
  return value.replace(WHITE_SPACE_REMOVAL_REGEX, "");
};

export const isEnglish = () => {
  return i18next.language.startsWith("en");
};
