import * as React from "react";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import "./RegistrationRequest.scss";
import "react-phone-number-input/style.css";
import { useTranslation } from "react-i18next";
import CustomTooltip from "../../Common/CustomTooltip/CustomTooltip";
import PhoneInput, { formatPhoneNumber } from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import { getPhoneInputCountries } from "../../../services/CountryService";
import en from "react-phone-number-input/locale/en.json";
import fr from "react-phone-number-input/locale/fr.json";
import {
  ERROR,
  LOGIN,
  REQUEST_ACCESS_CONFIRMATION,
} from "../../../constants/routePaths";
import { useNavigate } from "react-router";
import { Controller, useForm } from "react-hook-form";
import { EMAIL_REGEX, NAME_REGEX } from "../../../utils/Utils";
import {
  INCORRECT_EMAIL_MSG,
  MESSAGE,
  NUMBER_OR_SPECIAL_CHARS_NOT_ALLOWED,
  PHONE_NUMBER_INVALID_MESSAGE,
} from "../../Common/ErrorMessage/ErrorMessagesConstants";
import ErrorMessage from "../../Common/ErrorMessage/ErrorMessage";
import { isValidPhoneNumber } from "react-phone-number-input/max";
import ShortRegistrationData from "./ShortRegistrationData";
import { useParams } from "react-router-dom";
import { createSecureHttpClient } from "../../../utils/HttpClient";
import Registration from "./RegistrationData";
import { useAuth } from "../../../auth/AuthProvider";
import RegistrationConflictErrorMessage, {
  RegistrationConflictError,
} from "./RegistrationConflictErrorMessage";
import Modal from "../../Common/modal/Modal";
import { homePagePublicContext } from "../../../pages/Landing";

const initialRegistrationData = {
  firstName: "",
  lastName: "",
  email: "",
  countryCode: "CA",
  telephoneNumber: "",
  apps: undefined,
};

const ShortRegistrationRequest = () => {
  const { app } = useParams();
  let { getToken } = useAuth();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [registrationData, setRegistrationData] =
    useState<ShortRegistrationData>(initialRegistrationData);
  const [isPhoneNumRequired, setPhoneNumRequired] = useState(false);
  const [isPhoneNumValid, setPhoneNumValid] = useState(true);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [alertModelOpen, setAlertModalOpen] = useState(false);
  const [registrationErrorType, setRegistrationErrorType] =
    useState<RegistrationConflictError>();

  const [appId, setAppId] = useState<string>(null!);
  const homePageData = useContext(homePagePublicContext);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    control,
  } = useForm({
    mode: "all",
    defaultValues: initialRegistrationData,
  });

  useEffect(() => {
    const apps = homePageData?.categories
      .flatMap((c) => c.applications)
      .filter((a) => a.nameAlias?.toLowerCase() === app?.toLowerCase());

    if (apps?.length === 1) {
      //application id found for name alias
      setAppId(apps[0].id);
    } else {
      //application alias is unknown
      navigate(ERROR);
    }
  }, [homePageData, app, navigate]);

  useEffect(() => {
    document.title = t("browser.title.registration.page");
  }, [t]);

  const handlePhoneNumberBlur = () => {
    !registrationData.telephoneNumber
      ? setPhoneNumRequired(true)
      : setPhoneNumRequired(false);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    let value = event.target.value;
    setRegistrationData({
      ...registrationData,
      [name]: value.trim() === "" ? null : value.trim(),
    });
  };

  const handleInputOnBlur = (event: ChangeEvent<HTMLInputElement>) => {
    handleInputChange(event);
    const name: any = event.target.name;
    if (event.target.value === "") {
      setTimeout(
        () =>
          setError(name, {
            type: "required",
            message: "" + showErrorMessage(t(MESSAGE), name + "-required"),
          }),
        200
      );
    }
  };

  const isEnglish = () => {
    return i18n.language.startsWith("en");
  };

  const handleOnCancel = () => {
    navigate(LOGIN);
  };

  const handleFormSubmit = () => {
    setDisableSubmit(true);

    const phoneNumberAfterFormat = formatPhoneNumber(
      registrationData.telephoneNumber
    ).replace(/\D/g, "");

    createSecureHttpClient(getToken()!)
      .post<Registration>("/api/create-short-new-user-request", {
        ...registrationData,
        telephoneNumber: phoneNumberAfterFormat,
        apps: [{ id: appId }],
      })
      .then(() => {
        navigate(REQUEST_ACCESS_CONFIRMATION);
      })
      .catch((err) => {
        if (err && err.response) {
          if (err.response.status === 409) {
            //handles duplicate form or invalid email cases
            setRegistrationErrorType(err.response.data.message);
          } else if (err.response.status === 400) {
            //general errors e.g. application id is incorrect
            setRegistrationErrorType(
              RegistrationConflictError.ERROR_SHORT_FORM
            );
          }
        }

        setAlertModalOpen(true);
        setDisableSubmit(false);
      });
  };

  const clickSubmitForm = () => {
    handlePhoneNumberBlur();
  };

  const showErrorMessage = (msg: any, name: any) => {
    return (
      <div className="tds-landing-request-access-form-error">
        <ErrorMessage message={msg} fieldId={name} />
      </div>
    );
  };

  return (
    <>
      <div className="tds-homepage-container registration">
        <div className="tds-container">
          <div className="tds-divider-space-10">&nbsp;</div>
          <h2>{t("registration.title")}</h2>

          <form
            onSubmit={handleSubmit(handleFormSubmit)}
            className="tds-landing-request-access-form-container"
          >
            <div className="tds-landing-request-access-form-grid-container">
              <div className="tds-landing-form-group tds-landing-form-small-field form-group td-form-group-padding tds-landing-form-required">
                <label htmlFor="firstName">
                  {t("registration.first.name")}
                </label>
                <input
                  {...register("firstName", {
                    required: true,
                    pattern: NAME_REGEX,
                    setValueAs: (v) => (v !== null ? v.trim() : v),
                  })}
                  name="firstName"
                  type="text"
                  maxLength={25}
                  className={`form-control ${
                    errors?.firstName ? "is-invalid" : ""
                  }`}
                  id="firstName"
                  onBlur={handleInputOnBlur}
                  autoFocus={true}
                  aria-required="true"
                  aria-invalid={
                    errors.firstName?.type === "pattern" ? "true" : "false"
                  }
                  aria-describedby="firstName-required firstName-invalid"
                />
                {errors.firstName?.type === "required" &&
                  showErrorMessage(t(MESSAGE), "firstName-required")}
                {errors.firstName?.type === "pattern" &&
                  showErrorMessage(
                    t(NUMBER_OR_SPECIAL_CHARS_NOT_ALLOWED),
                    "firstName-invalid"
                  )}
              </div>

              <div className="tds-landing-form-group tds-landing-form-small-field form-group td-form-group-padding tds-landing-form-required">
                <label htmlFor="lastName">{t("registration.last.name")}</label>
                <input
                  {...register("lastName", {
                    required: true,
                    pattern: NAME_REGEX,
                    setValueAs: (v) => (v !== null ? v.trim() : v),
                  })}
                  name="lastName"
                  type="text"
                  maxLength={25}
                  className={`form-control ${
                    errors?.lastName ? "is-invalid" : ""
                  }`}
                  id="lastName"
                  onChange={handleInputChange}
                  aria-required="true"
                  aria-invalid={
                    errors.lastName?.type === "pattern" ? "true" : "false"
                  }
                  aria-describedby="lastName-required lastName-invalid"
                />
                {errors.lastName?.type === "required" &&
                  showErrorMessage(t(MESSAGE), "lastName-required")}
                {errors.lastName?.type === "pattern" &&
                  showErrorMessage(
                    t(NUMBER_OR_SPECIAL_CHARS_NOT_ALLOWED),
                    "lastName-invalid"
                  )}
              </div>

              <div className="tds-landing-form-group tds-landing-form-small-field form-group td-form-group-padding tds-landing-form-required">
                <label htmlFor="email">{t("registration.email")}</label>
                <input
                  {...register("email", {
                    required: true,
                    pattern: EMAIL_REGEX,
                    setValueAs: (v) => (v !== null ? v.trim() : v),
                  })}
                  name="email"
                  type="text"
                  maxLength={256}
                  className={`form-control ${
                    errors?.email ? "is-invalid" : ""
                  }`}
                  id="email"
                  onChange={handleInputChange}
                  aria-required="true"
                  aria-invalid={
                    errors.email?.type === "pattern" ? "true" : "false"
                  }
                  aria-describedby="email-required email-invalid"
                />
                {errors.email?.type === "required" &&
                  showErrorMessage(t(MESSAGE), "email-required")}
                {errors.email?.type === "pattern" &&
                  showErrorMessage(t(INCORRECT_EMAIL_MSG), "email-invalid")}
              </div>
              <div className="tds-landing-form-group tds-landing-form-small-field form-group td-form-group-padding tds-landing-form-required">
                <label htmlFor="telephoneNumber">
                  {t("registration.mobile.phone.number")}
                </label>
                <span className="descriptor-text">
                  <CustomTooltip
                    id="phone-number-tooltip"
                    descriptorText="phone.number.descriptor.text"
                    toolTipMessage="phone.number.tooltip.message"
                    toolTipDescriptorClass={
                      isEnglish()
                        ? "tooltip-descriptor-text"
                        : "tooltip-descriptor-text tooltip-descriptor-left-padding-for-fr"
                    }
                    containerClassName="tooltip-msg-box"
                  />
                </span>
                <Controller
                  name="telephoneNumber"
                  control={control}
                  rules={{
                    validate: (value) => isValidPhoneNumber(value),
                    required: true,
                  }}
                  render={({ field: { onChange } }) => (
                    <PhoneInput
                      flags={flags}
                      name="telephoneNumber"
                      id="telephoneNumber"
                      initialValueFormat="national"
                      defaultCountry="CA"
                      international={true}
                      withCountryCallingCode={true}
                      countryCallingCodeEditable={false}
                      addInternationalOption={false}
                      limitMaxLength={true}
                      onBlur={(event) => {
                        handlePhoneNumberBlur();
                        let number = event.target as HTMLInputElement;
                        number.value && isValidPhoneNumber(number.value)
                          ? setPhoneNumValid(true)
                          : setPhoneNumValid(false);
                      }}
                      countries={getPhoneInputCountries()}
                      labels={isEnglish() ? en : fr}
                      countryOptionsOrder={["CA", "US", "GB", "IE", "SG"]}
                      focusInputOnCountrySelection={true}
                      aria-required="true"
                      aria-describedby="telephoneNumber-required telephoneNumber-invalid"
                      aria-invalid={
                        errors.telephoneNumber?.type === "pattern"
                          ? "true"
                          : "false"
                      }
                      className={
                        (isPhoneNumRequired &&
                          !registrationData.telephoneNumber) ||
                        !isPhoneNumValid
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      onChange={(val) => {
                        onChange(val);
                        setRegistrationData({
                          ...registrationData,
                          telephoneNumber: val ? val!.toString() : "",
                        });
                      }}
                    />
                  )}
                />
                {isPhoneNumRequired &&
                  !registrationData.telephoneNumber &&
                  showErrorMessage(t(MESSAGE), "telephoneNumber-required")}
                {registrationData.telephoneNumber &&
                  !isPhoneNumValid &&
                  showErrorMessage(
                    t(PHONE_NUMBER_INVALID_MESSAGE),
                    "telephoneNumber-invalid"
                  )}
              </div>
            </div>

            <div className="tds-landing-form-buttons">
              <button
                className="btn td-btn-secondary-clear"
                onClick={handleOnCancel}
              >
                {t("registration.cancel")}
              </button>
              <button
                className="btn td-btn-primary-light"
                type="submit"
                onClick={() => clickSubmitForm()}
                disabled={disableSubmit}
              >
                {t("registration.submit")}
              </button>
            </div>
            <div style={{ height: 200 }}>&nbsp;</div>
          </form>

          <Modal
            id="registration-alert-modal-01"
            className="registration-error-modal"
            isOpen={alertModelOpen}
            showHideModal={() => setAlertModalOpen(!alertModelOpen)}
            actionButtonClickHandler={() => setAlertModalOpen(!alertModelOpen)}
            actionButtonText="OK"
          >
            {registrationErrorType && (
              <RegistrationConflictErrorMessage
                error={registrationErrorType!}
              />
            )}
          </Modal>
        </div>
      </div>
    </>
  );
};

export default ShortRegistrationRequest;
